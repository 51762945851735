<template>
     <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
			<div>
                <v-row justify="space-around">
                    <v-col cols="12" md="5" >
                        <div class="font-weight-bold body-1 text-center">
                           Solicitar vacaciones
                        </div>
                    </v-col>
                </v-row>

                <vacationItem 
                    color="#4EA0F2"
                    :yearNumber="activeYear"
                    :takenDays="takenDays"
                    :vacationDaysAvailable="accumulatedDays"
                    :percentageDays="percentageDays"
                    :titleConcat="`${daysAvailable} días por tomar`"
                ></vacationItem>

                <v-row justify="space-around">
                    <v-col cols="12" md="5" class="employee-request-vacation-container">
                        <span class="employee-request-vacation-message">
                            Recuerda que debes solicitar tus vacaciones con al menos 
                            una semana de anticipación para hacerlas efectivas y
                            deberán ser aprobadas por tu jefe directo antes de su fecha 
                            de inicio.
                        </span>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12">
                        <v-form ref="form" @submit.prevent="save">
                            <request-form ref="ReqEmployeVacForm" :values="form" @save="save" :serverErrors="serverErrors"></request-form>
                        </v-form>
                    </v-col>
                    
                </v-row>

               
               
                

            
                
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" xl="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mb-3"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCardMobile>


</template>

<script>
import Utils from '../../../helpers/Utils';
// import Utils from '../../../helpers/Utils';
import Form from "./FormRequest.vue";
export default {
    components: {
        'request-form': Form,
    },
	data(){
		return {
            loading: false,
            vacationsData: [],
            activeYear: 0,
            accumulatedDays: 0,
            takenDays:0,
            percentageDays: 0,
            daysAvailable:0,
            
           
            cancelBtnData: {
                text: "Cancelar",
                icon: "",
                to: "EmployeeVacations",
                block:true,	
                click: () => {}
            },
            saveBtnData: {
                text: "Enviar",
                icon: "",	
                click: this.save,
                block:true,
                loading: false,
            },
            serverErrors: {},
            form:{
                start_date: "",
                end_date: "",
                // required_supervisor_auth: false 
            },
            
           
		}
    },
    watch: {
       
        
    },
    mounted(){
        this.index();
    },
	methods: {
        index(){
            this.vacationsData = [];
            this.loading = true;
            this.daysAvailable = 0;
            this.takenDays = 0;
            this.accumulatedDays = 0;
            this.$api.employeeVacations.list()
				.then((resp) =>{
                    if(resp.data?.current_vacation?.status == "pendiente"){
                        this.$router.push({name: 'EmployeeVacations'});
                    }
                    if(resp.data.periods.length == 0)
                        return;

                    this.vacationsData = resp.data.periods.map((x) => {
                        x.takenDays = x.vacation_days_available - x.vacation_days_pending;
                        x.percentageDays = (x.takenDays * 100) /  x.vacation_days_available;
                        return x;
                    });

                    this.vacationsData = this.vacationsData.sort((a, b) => {
                        if (a.year_number < b.year_number)
                            return 1;
                        if (a.year_number > b.year_number)
                            return -1;
                        return 0;
                    })

                    this.activeYear = this.vacationsData[0]?.year_number??0;

                    this.vacationsData.forEach(it => {
                        this.accumulatedDays = this.accumulatedDays + it.vacation_days_available;
                        this.takenDays = this.takenDays + it.takenDays;
                        this.daysAvailable = this.daysAvailable + it.vacation_days_pending
                    });

                    this.percentageDays = (this.takenDays * 100) /  this.accumulatedDays;

                    
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
        validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;
			return this.$refs.form.validate();
            // return true;
		},
        save(){
            
            //console.log(this.form);
            
            if(this.validate()){
                
                let form = this.form;
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.employeeVacations.createRequest(form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'EmployeeVacations'});
                    })
                    .catch((error) => {
                        //console.log(error.response)
                        var errors = []
                        switch(error.response.status){
                            case 400: 
                                //console.log(99, error.response);
                                if(("message" in error.response.data)){
                                    Object.keys(error.response.data.message).forEach((x) => {
                                        //console.log(x);
                                        this.serverErrors = error.response.data.message;
                                        error.response.data.message[x].forEach((y) => {
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            errors.push(Utils.deleteWord(x,y))
                                        })
                                    });
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },

        
    },
}
</script>

<style lang="scss">
    .employee-request-vacation{

        &-container{
            line-height: 15px !important;
        }
         &-message{
            font-size: 0.8rem;
        }
    }
    

        
</style>
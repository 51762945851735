<template>
    <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
			<div>
                <v-row justify="space-around" v-if="vacationsData.length != 0">
                    <v-col cols="12" md="5" >
                        <div class="employee-vacations-gauge-container">
                            <span class="employee-vacations-gauge-title">
                                {{activeYear}}º año en curso
                            </span>
                            <rockiot-ui
                                gaugeClass="test-gaurge"
                                :name="daysAvailable"
                                units="días por tomar"
                                svgheight="250"
                                svgwidth="300"
                                type="gauge"
                                variation="radial"
                                settings="0"
                                bar-color="#707070"
                                progress-color="#4EA0F2"
                                text-color="#4EA0F2"
                                value-color="transparent"
                                ticks="1"
                                radius="lg" 
                                size="sm" 
                                scale="0" 
                                serial="001"
                                min="0"
                                :max="maxGraph"
                                :value="daysAvailable">
                            </rockiot-ui>
                            <div class="employee-vacations-gauge-subinfo">
                                <span ><span class="employee-vacations-gauge-subinfo-number">{{accumulatedDays}}</span> días acumulados</span>
                                <span><span class="employee-vacations-gauge-subinfo-number">{{takenDays}}</span> días tomados</span>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <div v-if="!loading && vacationsData.length == 0" class="text-center">
                            <span>Sin resultados</span>
                        </div>
                    </v-col>
                </v-row>

                <vacationItem v-for="(item, index) in vacationsData" :key="index" 
                    :color="item.color"
                    :yearNumber="item.year_number"
                    :takenDays="item.takenDays"
                    :vacationDaysAvailable="item.vacationDaysAvailable"
                    :percentageDays="item.percentageDays"
                ></vacationItem>
                

                

            
                
            </div>

            <v-row v-if="vacationsData.length != 0" justify="center" align="end">
				<v-col cols="12" md="5" xl="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <primary-button :props="requestBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCardMobile>


</template>

<script>
// import Utils from '../../../helpers/Utils';
export default {
   
	data(){
		return {
            loading: false,
            
            vacationsData: [],
            activeYear: 0,
            accumulatedDays: 0,
            takenDays:0,
            daysAvailable:0,
            maxGraph: 0,
            rawData: [],

            vacationStatus: null,
            vacationId: "",

            requestBtnData: {
                text: "Solicitar vacaciones",
                icon: "",	
                
                click: this.toRequestBtn,
                block:true,
                loading: false,
                disabled: false,
            },
            colors: [
                '#FFB236',
                '#6CC823',
                '#BE30FD',
                '#FF0000',
                '#FFFF00',
                '#009AFF',
                '#249A89',
                '#A0A088',
                '#1C6199',

                '#FFB236',
                '#6CC823',
                '#BE30FD',
                '#FF0000',
                '#FFFF00',
                '#009AFF',
                '#249A89',
                '#A0A088',
                '#1C6199',

            ]
		}
    },
    watch: {
        
     
      
    },
    mounted(){
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
       this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.requestBtnData.block = true;
            }
            else{
                this.requestBtnData.block = false;
            }
        },
        index(){
            this.vacationsData = [];
            this.loading = true;
            this.daysAvailable = 0;
            this.takenDays = 0;
            this.accumulatedDays = 0;
            this.vacationStatus = null;
            this.$api.employeeVacations.list()
				.then((resp) =>{
                    console.log(resp)
                    
                    if(resp.data.periods.length == 0)
                        return;
                    this.vacationsData = resp.data.periods.map((x) => {
                        x.takenDays = x.vacation_days_available - x.vacation_days_pending;
                        x.percentageDays = (x.takenDays * 100) /  x.vacation_days_available;
                        x.color = this.colors[x.year_number - 1];
                        return x;
                    });

                    this.vacationsData = this.vacationsData.sort((a, b) => {
                        if (a.year_number < b.year_number)
                            return 1;
                        if (a.year_number > b.year_number)
                            return -1;
                        return 0;
                    })

                    this.activeYear = this.vacationsData[0]?.year_number??0;

                    this.vacationsData.forEach(it => {
                        this.accumulatedDays = this.accumulatedDays + it.vacation_days_available;
                        this.takenDays = this.takenDays + it.takenDays;
                        this.maxGraph = this.maxGraph + it.expired_days + it.takenDays;
                        this.daysAvailable = this.daysAvailable + it.vacation_days_pending
                    });

                    this.setDataRequestBtn(resp.data.current_vacation);
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },

        setDataRequestBtn(currentVacation)
        {

            if(currentVacation == null){
                this.requestBtnData.disabled = false;
                this.vacationStatus = null;
                this.requestBtnData.text = "Solicitar vacaciones";
                return;
            }
            this.vacationStatus = currentVacation?.status;
            this.vacationId = currentVacation?.id;
            if(currentVacation?.status == "pendiente"){
                this.requestBtnData.disabled = true;
                this.requestBtnData.text = "Vacaciones solicitadas";
                return;
            }

            if(currentVacation?.status == "autorizada"){
                this.requestBtnData.disabled = false;
                this.requestBtnData.text = "Confirmar vacaciones";
                return;
            }

            if(currentVacation?.status == "confirmada"){
                this.requestBtnData.disabled = true;
                this.vacationStatus = null;
                this.requestBtnData.text = "Solicitar vacaciones";
                return;
            }
        },
        

        toRequestBtn()
        {
            if(this.vacationStatus == null){
                this.$router.push({name: 'EmployeeVacationsRequest'});
                return;
            }
                
            if(this.vacationStatus == "autorizada"){
                this.$router.push(`/m/vacations/${this.vacationId }/confirmation`);
                return;
            }

            if(this.vacationStatus == "confirmada"){
                this.$router.push({name: 'EmployeeVacationsRequest'});
                return;
            }
        }
    },


}
</script>

<style lang="scss">
    
    rockiot-ui{
        margin-top:-50px;
        
    }

    .employee-vacations{

        &-gauge-title{
            font-weight: 500;
            font-size: 0.8rem;
        }

        &-gauge-container{
            padding: 10px 20px;
            border-radius: 8px;
            background-color: rgb(236, 236, 236);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-gauge-subinfo{
            display: flex;
            justify-content: center;
            gap: 20px;
            font-size: 0.65rem;
            span{
                width: min-content;
                text-align: center;
            }
            &-number{
                font-size: 1.1rem;
                font-weight: 500;
            }
        }





    }

    .gauge-001{
        
        text:nth-of-type(2){
            font-weight: 500 !important;
            font-size: 0.7rem;
            transform: translateY(15px);

        }
        text:nth-of-type(3){
            font-size: 0.2rem;
            transform: translateY(25px);
            color: #707070;
        }
           
        
        
    }

        
</style>
<template>

<v-row justify="center" v-if="event != null">
    <!-- <v-col cols="2"> -->
        <v-dialog
        v-model="value"
        width="500"
      
        content-class="event-visual-dialog"
    >
        
        <div class="event-visual-container">
            <div class="event-visual-close-btn">
                <v-btn
                color="primary"
                fab
                x-small
                depressed
                @click="value = !value"
                >
                <v-icon color="black" size="25" dense class="px-0 mx-0 py-0 my-0 event-visual-close-icon">mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="event-visual-image">
                <v-img
                    :src="event.image_url"
                    max-height="500"
                    height="500"
                    class="grey darken-4"
                    
                ></v-img>
            </div>

            <div class="event-visual-slider-container">
                <SwipeButton
                    v-if="!event.confirmed_attendance && event.has_attendance"
                    ref="swipeButton"
                    class="swipe-button"
                    @actionConfirmed="onActionConfirmed"
                    initialInstructionText="Me interesa asistir"
                    sliderBackgroundColor="#ffdf31"
                    textColor="#444444"
                    overlayColor="#FFB40A"
                    buttonColor="#FFB40A"
                    :loading="loadingSwipe"
                />
                <SwipeButton-rl
                    v-if="event.confirmed_attendance && event.has_attendance"
                    ref="swipeButtonrl"
                    class="swipe-button"
                    @actionConfirmed="onActionConfirmedCancel"
                    initialInstructionText="Cancelar asistencia"
                    sliderBackgroundColor="#de0000"
                    textColor="#fff"
                    overlayColor="#FFB40A"
                    buttonColor="#FFB40A"
                    :loading="loadingSwipe"
                ></SwipeButton-rl>
            </div>
        </div>
             
     
      
    </v-dialog>
    
    <!-- </v-col> -->
    
  </v-row>
   
  
</template>

<script>
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// import { useSwipe } from '@vueuse/core'

export default {
    props: ['overlayValue', 'event', 'loadingSwipe'],
    components: {
        // VueSlickCarousel
    },
    computed: {
        value: {
            get: function() {
                return this.overlayValue
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        },
    },
    data() {
        return {
            clicked: false,
            positionY:0,
            slickOptions: {
            dots: false,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                vertical: true,
                verticalSwiping: false,
                // touchMove:true,
                // swipe:true,
                // swipeToSlide:true,
            }
        }
    },
    methods:{
        resetSwipe(){
            try {
                this.$refs?.swipeButton?.reset();
            } catch (error) {
                console.log("reset not found")
            }
        },
        onActionConfirmed() {
            this.$emit('confirmed')
            // setTimeout(() => {
            //     this.$refs.swipeButton.reset();
            // }, 1000);
        },
        onActionConfirmedCancel() {
            this.$emit('canceled')
            // setTimeout(() => {
            //     this.$refs.swipeButton.reset();
            // }, 1000);
        },
        next(){
            console.log("entra")
            this.$refs.carousel.next()
        },
        back(){
            console.log("entra")
            this.$refs.carousel.prev()
        },
        mousedown(event) {
            console.log('mouseneter');
            console.log(event.clientX, event.clientY);
            this.positionY = event.clientY;
            this.clicked = true;
            this.$el.addEventListener('mousemove', this.mouseMove, false);
        },
        mouseup() {
            this.clicked = false;
            // this.positionY = 0;
            // this.$el.removeEventListener('mousemove', this.mouseMove());
        },
        mouseMove(event) {
            if(this.clicked){

                if(this.positionY < event.clientY){
                    console.log("down")
                    this.positionY = event.clientY;
                    this.$refs.carousel.prev()
                    return;    
                }
                if(this.positionY > event.clientY){
                    console.log("up")
                    this.positionY = event.clientY;
                     this.$refs.carousel.next()
                    return
                }
            }
            // this.positionY = event.clientY - 1;
            // if(this.clicked)
            //     console.log(event.clientX, event.clientY);
        }
    },
    mounted() {
    },
}
</script>

<style lang="scss">
    .event-visual{
        &-dialog{
            box-shadow: none !important;
            scrollbar-width: none;
            // height: 100vh;
            // width: min-content;
        }
        &-card{
            // margin-top: 50px;
        }
        &-main{
            width: 100vw;
            z-index: 10;
        }
        &-container {
            // height: 50svh;
            padding: 15px 15px 10px 10px;
            // background-color: blue;
            position: relative;
        }

        &-image{
            // background-color: red;
            height: 500px;
            width: 100%;
        }

        &-slider-container{
            position: relative;
            z-index: 1000;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            margin-top: -20px;
        }

        &-close-btn{
            z-index: 100;
            position: absolute;
            top: 1px;
            right: 1px;
        }

        &-close-icon{
            height: 27px !important;
            width: 24px !important;
        }
    }
</style>
<template>
    <contentCardMobile :loading="false" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
            <div class="">
                <v-row justify="space-around">
                    <v-col  cols="12" md="5" class="pb-0">
                        <alternativeDatePickerRangeVCalendar
                            :valueInput="{start: dateRange.start_date, end: dateRange.end_date}"
                            @valueChange="(v) => {dateRange.start_date = v.start; dateRange.end_date = v.end; index(); delete serverErrors['date']}"
                            label="Fechas"
                        ></alternativeDatePickerRangeVCalendar>
                    </v-col>
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="12" md="5" class="pb-0 pt-1"
                        >
                            <v-chip-group
                            mandatory
                            active-class="employee-attendance-chip-selected"
                            v-model="chipSelected"
                            >
                                <v-chip
                                    :value="0"
                                    class="employee-attendance-chip"
                                    
                                >
                                    <span class="black--text">Todo</span>
                                </v-chip>

                                <v-chip
                                    :value="1"
                                    class="employee-attendance-chip"
                                    
                                    
                                >
                                    <span class="black--text">Inasistencias</span>
                                </v-chip>

                                <v-chip
                                    :value="2"
                                    class="employee-attendance-chip"
                                    
                                    
                                >
                                    <span class="black--text">Permisos</span>
                                </v-chip>
                            </v-chip-group>
                    </v-col>
                </v-row>
            

                <v-row justify="space-around">
                    <v-col
                        cols="12"
                        md="5"
                        >
                            <div class="text-center" v-if="loading">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </div>

                            <div v-if="!loading && attendancesData.length == 0" class="text-center">
                                <span>Sin resultados</span>
                            </div>
                        
                            <div class="employee-attendance-container-item" v-for="(item, index) in attendancesData" :key="index" >
                                <span class="employee-attendance-item-title">{{item.monthText}} {{item.year}}</span>
                                
                                <div class="employee-attendance-item mb-3" v-for="(subitem, index2) in item.items" :key="index2">
                                    <span class="employee-attendance-item-subtitle">{{subitem.typeTextTitle}}</span>
                                    <div class="employee-attendance-item-details">
                                        <span>{{subitem.typeTextDetails}}</span>
                                        <span>{{subitem.date}}</span>
                                    </div>
                                    <v-divider></v-divider>
                                </div>
                            
                            </div>
                    </v-col>
                </v-row>
            </div>
        </template>
    </contentCardMobile>
</template>

<script>
import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';
export default {
   
	data(){
		return {
            loading: true,
            dateRange: {
                start_date: PageStateManager.getState(this.$router.history.current.path, 'start_date')??Utils.getStartDate(), 
                end_date: PageStateManager.getState(this.$router.history.current.path, 'end_date')?? new Date().toISOString().split('T')[0]
            },
            chipSelected: 0,
            attendancesData: [],
            rawData: []
		}
    },
    watch: {
        
        [`chipSelected`]: function () {
            this.index();
        },
     
      
    },
    mounted(){
       this.index();
    },
	methods: {
        index(){
            this.attendancesData = [];
            this.loading = true;
            this.$api.employeeAttendances.list(this.chipSelected, this.dateRange.start_date, this.dateRange.end_date)
				.then((resp) =>{
                    this.rawData = JSON.parse(JSON.stringify(resp.data));
					this.processAttendances(resp.data);
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
        processAttendances(info){
            let data = info.sort((a, b) => {
            if (a.date < b.date)
                return -1;
            if (a.date > b.date)
                return 1;
            return 0;
            })

            data.forEach(elem => {
                if(elem.type != null){
                    let dateDiv = elem.date.split("-");
                    let found = this.attendancesData.findIndex((itm) => itm.dateId == `${dateDiv[0]}-${dateDiv[1]}`);
                    console.log(found)
                    const dateData = this.getDateTexts(dateDiv)
                    elem.date = Utils.globalDateFormat(elem.date)
                    elem.typeTextDetails = this.getTextDetailType(elem.type)
                    elem.typeTextTitle = this.getTextTitleType(elem.type)
                    if(found > -1){
                        this.attendancesData[found].items.push(elem);
                    }else{
                        this.attendancesData.push({
                            dateId: `${dateDiv[0]}-${dateDiv[1]}`,
                            monthText: dateData.monthText,
                            year:dateData.year,
                            items: [elem]
                        });
                    }
                }
                
            });

        },
        getTextTitleType(type){
            switch (type) {
               
                case 'AA':
                    return "Asistencia"
                case 'FI':
                    return "Inasistencia"
                case 'FJ':
                    return "Falta justificada"
                case 'DE':
                    return "Descanso Lunes - Domingo"
                case 'FE':
                    return "Feriado"
                case 'IN':
                    return "Incapacidad"
                case 'PG':
                    return "Permiso con goce de sueldo"
            
                case 'PS':
                    return "Permiso sin goce de sueldo"
            
                case 'DX':
                    return "Descanso Laborado"
            
                case 'VA':
                    return "Vacaciones"
            
                default:
                    return "N/A"
            }
        },
        getTextDetailType(type){
            switch (type) {
                case 'AA':
                    return "Asistencia"
                case "FI":
                    return "No presenta justificante de ningún tipo";
                case 'FJ':
                    return "Falta justificada"
                case 'DE':
                    return "Descanso Lunes - Domingo"
                case 'FE':
                    return "Feriado"
                case 'IN':
                    return "Incapacidad"
                case 'PG':
                    return "Permiso con goce de sueldo"
            
                case 'PS':
                    return "Permiso sin goce de sueldo"
            
                case 'DX':
                    return "Descanso Laborado"
            
                case 'VA':
                    return "Vacaciones"
            
                default:
                    return "N/A"
            }
        },
        getDateTexts(dateArray){
            let date = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  
            let ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(
                date
            );
            let mo = new Intl.DateTimeFormat("es", { month: "long" }).format(
                date
            );
            let moNumber = new Intl.DateTimeFormat("es", { month: "2-digit" }).format(
                date
            );
            let da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(date);

            return {
                year:ye,
                monthText:mo,
                monthNumber:moNumber,
                day:da
            }
        }
    },
}
</script>

<style lang="scss"> 
    .employee-attendance-chip{
        background-color: #d6d6d7 !important;
        &-selected{
            background-color: var(--v-primary-base) !important;
        }
    }

    .employee-attendance{
        &-container-item{
            display: flex;
            flex-direction: column;
        }

        &-item-title{
            font-weight: 600;
            font-size: 0.85rem;
        }
        &-item-subtitle{
            color: var(--v-primary-base);
            font-weight: 600;
            font-size: 0.8rem;
        }
        &-item-details{
            display: flex;
            justify-content: space-between;
            font-size: 0.7rem;
            :first-child{
                overflow-wrap: anywhere;
            }
        }
    }
</style>
<template>
	<div>
        <contentCard :loading="loading">
            <template slot="cardContent">
                <div>
                    <v-row align="center" justify="space-between">
                        <v-col cols="12" md="9" class="">
                            <v-row class="" justify="start" no-gutters>
                                <!-- <v-col cols="1"> -->
                                    <alternativeButton
                                        ref="returnbtn"
                                        :props="returnBtnData"
                                    ></alternativeButton>
                                <!-- </v-col> -->
                                <v-col class="ml-2 " align-self="center">
                                    <p class="mb-0 font-weight-bold text-col">{{employeeInfo}}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-tabs
                                v-model="tab"
                                centered
                                :show-arrows="true"
                                grow
                                active-class="font-weight-bold tab-active"
                                slider-size="3"
                                >
                                <v-tabs-slider></v-tabs-slider>

                                <v-tab href="#1"  :class="`tab-header  `" >
                                    Generales
                                </v-tab>

                                <v-tab href="#7"  :class="`tab-header  `" >
                                    Documentos
                                </v-tab>

                                <v-tab href="#2"  :class="`tab-header  `" >
                                    Eventualidades
                                </v-tab>

                                <v-tab href="#3" :class="`tab-header  `">
                                    Cuestionarios
                                </v-tab>

                                <v-tab href="#4" :class="`tab-header  `">
                                    Patrimonio
                                </v-tab>

                                <v-tab href="#5" :class="`tab-header  `" v-permission="'vacations.show'">
                                    Vacaciones
                                </v-tab>

                                <v-tab href="#6" :class="`tab-header  `">
                                    Puesto
                                </v-tab>
                            </v-tabs>

                        
                            <v-tabs-items v-model="tab">

                                <!-- GENERALES -->
                                <v-tab-item
                                    value="1"
                                >
                                
                                    <v-row >
                                        
                                        <v-col cols="12" lg="7" >
                                            <v-row justify="center" class="mt-5" v-if="$vuetify.breakpoint.mdAndDown">
                                                <v-col cols="12" class="text-center-web" >
                                                    <v-img
                                                    
                                                    
                                                    max-width="250"
                                                    :src="img"
                                                ></v-img>
                                                </v-col>

                                                <v-col cols="12" class="max-width-statics-btn">
                                                    <primary-button :props="exportBtnData"></primary-button>
                                                </v-col>
                                            </v-row>

                                            <separator title="PERSONALES"></separator>
                                            <details-item :detailsItems="generalPersonal"></details-item>

                                            <separator title="BENEFICIARIOS"></separator>
                                            <details-item :detailsItems="generalpayee"></details-item>

                                            <separator title="HIJOS/HIJAS"></separator>
                                            <details-item :detailsItems="generalChildren.info"></details-item>
                                            <details-item :class="index == 0 ? '': 'mt-5'" v-for="(items, index) in generalChildren.children" :key="index" :detailsItems="items"></details-item>

                                            <separator title="CONTRATACIÓN"></separator>
                                            <details-item :detailsItems="generalContract"></details-item>
                                            <!-- FILES -->
                                            <v-row justify="center" no-gutters>
                                                <v-col class="my-0 py-0">
                                                    <!-- <div v-for="(item, j) in detailsItems" :key="j"> -->
                                                        <v-row justify="center" align="center" no-gutters class="my-2">
                                                            <v-col align-self="start" ><span class="font-weight-bold normalText--text">PDF de contratos</span></v-col>
                                                            <!-- <v-col class="text-right">
                                                                <a target="_blank" :href="''"><v-icon  color="secondary" >mdi-open-in-new</v-icon></a>
                                                            </v-col> -->
                                                        </v-row>
                                                        <!-- <v-divider></v-divider> -->
                                                    <!-- </div> -->
                                                    <div v-for="(item, j) in files" :key="j">
                                                        <v-row justify="center" align="center" no-gutters class="my-2">
                                                            <v-col align-self="start" ><span class=" normalText--text">{{item.name}}</span></v-col>
                                                            <v-col class="text-right">
                                                                <a target="_blank" :href="item.url"><v-icon  color="secondary" >mdi-open-in-new</v-icon></a>
                                                            </v-col>
                                                        </v-row>
                                                        <!-- <v-divider></v-divider> -->
                                                    </div>
                                                </v-col>
                                            </v-row>

                                        </v-col>
                                        <v-col>
                                        <v-row justify="center" class="mt-5" v-if="$vuetify.breakpoint.lgAndUp">
                                                <v-col cols="12" class="text-center-web" >
                                                    <v-img
                                                    
                                                    
                                                    max-width="250"
                                                    :src="img"
                                                ></v-img>
                                                </v-col>

                                                <v-col cols="12" class="max-width-statics-btn">
                                                    <primary-button :props="exportBtnData"></primary-button>
                                                </v-col>
                                        </v-row>
                                        </v-col>
                                        
                                    </v-row>
                                </v-tab-item>



                                <!-- EVENTUALIDADES -->
                                <v-tab-item
                                    value="2"
                                >
                                
                                    <v-row>
                                        <v-col>
                                            <datatable :props="tableProps" @method_handler="method_handler">
                                            </datatable>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>

                                <!-- CUESTIONARIOS -->
                                <v-tab-item
                                    value="3"
                                >
                                    <div class="mt-2">
                                        <v-row class="my-0 mx-1" v-if="qList.length == 0 && !loadingQuestionaries">
                                            <v-col class="py-2">
                                                <p class="text-center">Sin información</p>
                                            </v-col>
                                        </v-row>
                                        <v-row class="my-0 mx-1" v-if="loadingQuestionaries">
                                            <v-col class="py-2 text-center">
                                                <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                ></v-progress-circular>
                                            </v-col>
                                        </v-row>
                                        <template v-if="!loadingQuestionaries">
                                            <v-row class="my-0 mx-1" v-for="q in qList" v-bind:key="q.id" >
                                                <v-col class="py-2">
                                                    <v-card width="100%" elevation="3" min-height="20">
                                                        <v-card-text>
                                                            <v-row justify="space-between">
                                                                <v-col class="py-2">
                                                                    <p class="mb-0 pb-0">{{q.text}}</p>
                                                                </v-col>
                                                                
                                                                <v-col align-self="center" class="p-6">
                                                                    <v-row justify="end" class="mr-1">
                                                                        <v-chip
                                                                            color="success"
                                                                            value=""
                                                                        >
                                                                            {{q.status}}
                                                                        </v-chip>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        
                                    </div>
                                </v-tab-item>


                                <!-- PATRIMONIO -->
                                <v-tab-item value="4">
                                    <v-row>
                                        <v-col>
                                            <datatable :props="tableAssignProps" @method_handler="method_handler">
                                            </datatable>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>


                                <!-- Vacaciones -->
                                <v-tab-item value="5">
                                    <v-row>
                                        <v-col>
                                            <datatable :props="tableVacations" @method_handler="method_handler">
                                            </datatable>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>

                                <!-- Position -->
                                <v-tab-item value="6">
                                    
                                    <div class="mt-2">
                                        
                                        <v-row class="my-0 mx-1" v-if="loadingPosition">
                                            <v-col class="py-2 text-center">
                                                <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                ></v-progress-circular>
                                            </v-col>
                                        </v-row>
                                        <template v-if="!loadingPosition">
                                            <v-row>
                                                <v-col>
                                                    <details-item :detailsItems="positionItems"></details-item>
                                                </v-col>
                                            </v-row>
                                        </template>
                                        
                                    </div>
                                </v-tab-item>


                                <v-tab-item value="7">
                                   <v-row>
                                        <v-col class="text-left ml-4">
                                            <div class=" body-1 mb-2">
                                                <span class="font-weight-bold">Documentos Base</span> ({{docTotalsString}})
                                            </div>
                                            <v-progress-linear background-color="inputsBackground" :indeterminate="loadingUpdate" :value=" loadingUpdate ? '0': percReqDoc" color="success" height="10"></v-progress-linear>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-form ref="form" @submit.prevent="update">
                                        <v-row>
                
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        
                                                        label=""
                                                        :valueInput="form.birthCertificate_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.birthCertificate_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Acta de nacimiento"
                                                        :multiple="false"
                                                        :valueInput="form.birthCertificate_file"
                                                        :url="form.birthCertificate_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('birthCertificate_url')}"
                                                        @valueChange="(v) => {form.birthCertificate_file = v; delete serverErrors[`documentation.ACTA DE NACIMIENTO`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.ACTA DE NACIMIENTO`]"
                                                        
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            
                                            </v-col>

                                            <v-col>
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.recomendationLetter_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.recomendationLetter_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Carta de recomendación"
                                                        :multiple="false"
                                                        :valueInput="form.recomendationLetter_file"
                                                        :url="form.recomendationLetter_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('recomendationLetter_url')}"
                                                        @valueChange="(v) => {form.recomendationLetter_file = v; delete serverErrors[`documentation.CARTA DE RECOMENDACIÓN`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.CARTA DE RECOMENDACIÓN`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>


                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.ine_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.ine_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Identificación oficial (INE)"
                                                        :multiple="false"
                                                        :valueInput="form.ine_file"
                                                        :url="form.ine_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('ine_url')}"
                                                        @valueChange="(v) => {form.ine_file = v; delete serverErrors[`documentation.IDENTIFICACIÓN OFICIAL`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.IDENTIFICACIÓN OFICIAL`]"
                                                        
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>

                                            <v-col>
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.psychometricTest_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.psychometricTest_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Pruebas psicométricas"
                                                        :multiple="false"
                                                        :valueInput="form.psychometricTest_file"
                                                        :rule="[]"
                                                        :url="form.psychometricTest_url"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('psychometricTest_url')}"
                                                        @valueChange="(v) => {form.psychometricTest_file = v; delete serverErrors[`documentation.PRUEBA PSICOMÉTRICA`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.PRUEBA PSICOMÉTRICA`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.curp_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.curp_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="C.U.R.P."
                                                        :multiple="false"
                                                        :valueInput="form.curp_file"
                                                        :url="form.curp_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('curp_url')}"
                                                        @valueChange="(v) => {form.curp_file = v; delete serverErrors[`documentation.CURP`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.CURP`]"
                                                        
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>

                                            <v-col>
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.internalRules_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.internalRules_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Reglamento interno de trabajo"
                                                        :multiple="false"
                                                        :valueInput="form.internalRules_file"
                                                        :url="form.internalRules_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('internalRules_url')}"
                                                        @valueChange="(v) => {form.internalRules_file = v; delete serverErrors[`documentation.REGLAMENTO INTERNO`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.REGLAMENTO INTERNO`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.rfc_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.rfc_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="R.F.C."
                                                        :multiple="false"
                                                        :valueInput="form.rfc_file"
                                                        :url="form.rfc_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('rfc_url')}"
                                                        @valueChange="(v) => {form.rfc_file = v; delete serverErrors[`documentation.RFC`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.RFC`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>

                                            <v-col>
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.confidential_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.confidential_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Convenio de confidencialidad"
                                                        :multiple="false"
                                                        :valueInput="form.confidential_file"
                                                        :url="form.confidential_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('confidential_url')}"
                                                        @valueChange="(v) => {form.confidential_file = v; delete serverErrors[`documentation.CONVENCIO CONFIDENCIAL`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.CONVENCIO CONFIDENCIAL`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>


                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.nss_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.nss_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="N.S.S."
                                                        :multiple="false"
                                                        :valueInput="form.nss_file"
                                                        :url="form.nss_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('nss_url')}"
                                                        @valueChange="(v) => {form.nss_file = v; delete serverErrors[`documentation.NSS`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.NSS`]"
                                                        
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>

                                            </v-col>

                                            <v-col>
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.payment_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.payment_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Propuesta económica"
                                                        :multiple="false"
                                                        :valueInput="form.payment_file"
                                                        :url="form.payment_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('payment_url')}"
                                                        @valueChange="(v) => {form.payment_file = v; delete serverErrors[`documentation.PROPUESTA ECONÓMICA`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.PROPUESTA ECONÓMICA`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.proofResidency_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.proofResidency_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Comprobante de domicilio"
                                                        :multiple="false"
                                                        :valueInput="form.proofResidency_file"
                                                        :url="form.proofResidency_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('proofResidency_url')}"
                                                        @valueChange="(v) => {form.proofResidency_file = v; delete serverErrors[`documentation.COMPROBANTE DE DOMICILIO`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.COMPROBANTE DE DOMICILIO`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>

                                            <v-col>
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.medicalTest_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.medicalTest_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Examen médico (opcional)"
                                                        :multiple="false"
                                                        :valueInput="form.medicalTest_file"
                                                        :url="form.medicalTest_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('medicalTest_url')}"
                                                        @valueChange="(v) => {form.medicalTest_file = v; delete serverErrors[`documentation.EXAMEN MÉDICO`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.EXAMEN MÉDICO`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.studyCert_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.studyCert_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Último certificado de estudios"
                                                        :multiple="false"
                                                        :valueInput="form.studyCert_file"
                                                        :url="form.studyCert_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('studyCert_url')}"
                                                        @valueChange="(v) => {form.studyCert_file = v; delete serverErrors[`documentation.CERTIFICADO DE ESTUDIOS`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.CERTIFICADO DE ESTUDIOS`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>

                                            <v-col>
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.letter_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.letter_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Carta de no antecedentes penales (opcional)"
                                                        :multiple="false"
                                                        :valueInput="form.letter_file"
                                                        :url="form.letter_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('letter_url')}"
                                                        @valueChange="(v) => {form.letter_file = v; delete serverErrors[`documentation.ANTECEDENTES PENALES`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.ANTECEDENTES PENALES`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>


                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <div class="d-flex">
                                                    <checkboxForm
                                                        label=""
                                                        :valueInput="form.economyStudy_required"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @valueChange="(v) => {form.economyStudy_required = (v == true ? 1:0); this.update()}"
                                                        class="mx-3 documents-check"
                                                    ></checkboxForm>
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Estudio socioeconómico"
                                                        :multiple="false"
                                                        :valueInput="form.economyStudy_file"
                                                        :url="form.economyStudy_url"
                                                        :rule="[]"
                                                        :disabled="loadingUpdate"
                                                        @cleared:file="(v) => { this.openDeleteDocument('economyStudy_url')}"
                                                        @valueChange="(v) => {form.economyStudy_file = v; delete serverErrors[`documentation.ESTUDIO SOCIOECONÓMICO`]; this.update()}"
                                                        :errorMessages="serverErrors[`documentation.ESTUDIO SOCIOECONÓMICO`]"
                                                        class="width-available"
                                                    ></fileInputForm>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!-- EXTRA -->
                                        <v-row>
                                            <v-col class="text-left pb-0">
                                                <div class="font-weight-bold body-1">
                                                    Documentos Extra
                                                </div>
                                            </v-col>
                                            
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="6" class="">
                                                <div class="d-flex">
                                                    
                                                    <fileInputForm
                                                        name="p2"
                                                        label="Selecciona un documento"
                                                        :multiple="true"
                                                        :valueInput="form.newEmployeeFiles"
                                                        :url="''"
                                                        :rule="[]"
                                                        :disabled="loadingMiscUpdate"
                                                        :loading="loadingMiscUpdate"
                                                        @valueChange="(v) => {form.newEmployeeFiles = v; delete serverErrors[`new_employee_files`];}"
                                                        :errorMessages="serverErrors[`new_employee_files`]"
                                                        v-on:update:url="form.economyStudy_url = $event"
                                                        class="width-available mr-2"
                                                    ></fileInputForm>

                                                    <secondary-button :props="miscUpdateBtnData" class=""></secondary-button>


                                                </div>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row>
                                            <v-col v-for="(item, index) in form.miscFiles" :key="index" cols="12" md="6">
                                                <fileInputForm
                                                    
                                                    :label="getMiscFileName(item.file)"
                                                    :multiple="false"
                                                    :valueInput="[]"
                                                    :url="item.file_url"
                                                    :rule="[]"
                                                    :disabled="true"
                                                    :loading="item.loading"
                                                    
                                                    @cleared:file="(v) => {openDeleteDocument(item, true)}"
                                                    
                                                    class="width-available"
                                                ></fileInputForm>
                                            </v-col>
                                            
                                        </v-row>

                                    </v-form>


                                </v-tab-item>

                            </v-tabs-items>

                            
                        </v-col>
                    </v-row>

                </div>
            </template>
        </contentCard>


        <!-- DELETE EVENTS -->
		<modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col  cols="12" md="4">
						<secondaryButton
							:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col  cols="12" md="4">
						<primaryButton
							:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <!-- DELETE FILES -->
        <modal :props="modalDocumentsProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" md="4">
						<secondaryButton
						:props="cancelDocumentsModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" md="4">
						<primaryButton
						:props="acceptDocumentsModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>



		<modal :props="modalDeleteVacationItemProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" md="4">
						<secondaryButton
							:props="cancelDeleteVacationItemModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" md="4">
						<primaryButton
							:props="acceptDeleteVacationItemModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>

        <!-- holiday modal -->
        <modal :props="modalHolidayProps" @modalResponse="method_handler">
            <!-- Body -->
            <template slot="cardBody">
                <datatable :props="tableVacationsDetail" @method_handler="method_handler">
                </datatable>
            </template>
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					
					<v-col cols="12" md="3">
						<primaryButton
							:props="cancelHolidayModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>


		
	</div>
</template>
<script>
import Utils from "../../../../helpers/Utils";
import DetailsItem from '../../../../components/DetailsItem.vue';
import separator from '../../../../components/Separator.vue';
import Object2Form from '../../../../helpers/Object2Form';
export default {
	components: {
        DetailsItem,
        separator
    },
    computed:{
        employeeInfo: function(){
            //return `00001 - Dulce Ulibarri - Grupo Merca`;
			return this.employeeText;
        },
        img: function(){
            // return "https://picsum.photos/id/11/500/300"

            return this.employeeImg;
        },

    },
	watch:{
		tab: function (value) {
            switch (value) {
				case '2':
					this.getEvents();
					break;
			
				case '3':
					console.log('Load Questionaries');
					break;

				case '4':
					this.getAssets();
					break;

                case '5':
                    this.getHoliday();
                    break;

                case '6':
                    this.getPosition(this.positionId);
                    break;
			}
        }
	},
    data(){
		return {
            loadingUpdate:false,
            loadingMiscUpdate:false,
            loadingMiscDelete:false,
            documentSelected: "",
            filesLoading:[],
            id: this.$route.params.id,
			employeeText: '',
            employeeNumber: '',
            employeeImg: "",
            employeeName:"",
            generalPersonal: [],
            generalpayee: [],
            generalChildren:{info:[], children:[]},
            generalContract: [],
            files:[],
            docTotalsString: '',
            percReqDoc: 0,
			loading: false,
			tab: "1",
			loadingQuestionaries: false,
			eventsLoaded: false,
			questionariesLoaded: false,
			eventId:'',
            vacationId: "",
            yearVacationId:"", 
			assetsLoadded: false,
            holidayLoaded: false,
            loadingPosition: false,
            positionId: "",
            serverErrors: {},
            miscUpdateBtnData: {
                text: "Guardar",
                icon: "",
                color: "primary",
                
                click: () => {this.updateMiscFiles()}
            },
            form: {
                /**GENERAL */
                employe_number: '',
                name: '',
                email: '',
                business_email: '',
                profession: '',
                birthplace: '',
                birthdate: '',
                gender: '',
                marital_status: '',
                curp: '',
                rfc: '',
                infonavit_number: '',
                fonacot_number: '',
                nss: '',
                phone: '',
                blood_type: '',
                diseases: [],
                other_diseases:'',
                country: '',
                street: '',
                ext_number: '',
                int_number: '',
                zip_code: '',
                neighborhood: '',
                city: '',
                state: '',
                emergency_contact: '',
                emergency_number: '',
                payee_name: '',
                payee_family_phone: '',
                payee_family_relationship: '',
                photo_file: [],
                photo_url: null,
                has_childs: false,
                children: [],

                /**DOCUMENTATION */
                birthCertificate_file: [],
                birthCertificate_url: '',
                birthCertificate_required: 0,
                ine_file: [],
                ine_url: '',
                ine_required: 0,
                curp_file: [],
                curp_url: '',
                curp_required: 0,
                rfc_file: [],
                rfc_url: '',
                rfc_required: 0,
                nss_file: [],
                nss_url: '',
                nss_required: 0,
                proofResidency_file: [],
                proofResidency_url: '',
                proofResidency_required: 0,
                studyCert_file: [],
                studyCert_url: '',
                studyCert_required: 0,
                economyStudy_file: [],
                economyStudy_url: '',
                economyStudy_required: 0,
                recomendationLetter_file: [],
                recomendationLetter_url: '',
                recomendationLetter_required: 0,
                psychometricTest_file: [],
                psychometricTest_url: '',
                psychometricTest_required: 0,
                internalRules_file: [],
                internalRules_url: '',
                internalRules_required: 0,
                confidential_file: [],
                confidential_url: '',
                confidential_required: 0,
                payment_file: [],
                payment_url: '',
                payment_required: 0,
                medicalTest_file: [],
                medicalTest_url: '',
                medicalTest_required: 0,
                letter_file: [],
                letter_url: '',
                letter_required: 0,
                miscFiles:[],
                newEmployeeFiles:[],
                deleteEmployeeFiles: [],

                /**HIRE */
                company: '',
                branch_office_id: '',
                admission_date: '',
                type: '',
                start_date:'',
                end_date: '',
                mode: '',
                position_id: '',
                payroll_type: '',
                fiscal_salary: '',
                integrated_salary: '',
                support_transportation: '',
                mealTimeCheck: '',
                repeatWeekdaysTimes: '', //FALTA EN BACK
                monday_schedule_start: '',
                monday_schedule_end: '',
                tuesday_schedule_start: '',
                tuesday_schedule_end: '',
                wednesday_schedule_start: '',
                wednesday_schedule_end: '',
                thursday_schedule_start: '',
                thursday_schedule_end: '',
                friday_schedule_start: '',
                friday_schedule_end: '',
                saturday_schedule_start: '',
                saturday_schedule_end: '',
                sunday_schedule_start: '',
                sunday_schedule_end: '',
                contract_file: [],
                contract_path: '',

                contract_multifiles: [],
                delete_contract_files: [],



            },
			qList: [
				// {text: "Cuestionario de clima laboral 2021", status: "Completo", id: 1},
				// {text: "Cuestionario de clima laboral 2020", status: "Completo", id: 2},
				// {text: "Cuestionario de manejo de la pandemia", status: "Completo", id: 3}
			],

            positionItems: [
                { concepto: 'Código', index: 'code', valor: '' },
                { concepto: 'Nombre', index: 'name', valor: null },
                { concepto: 'Empresa', index: 'area.company.name', valor: null},
                { concepto: 'Área', index: 'area.name', valor: null},
                { concepto: 'Departamento', index: 'department.name', valor: null},
                { concepto: 'Requiere viajar', index: 'requires_travel', valor: null},
                { concepto: 'A cargo de gente', index: 'have_persons_in_charge', valor: null},
                { concepto: 'Requiere inglés', index: 'requires_english', valor: null},
                { concepto: 'Nivel de inglés requierido', index: 'english_level', valor: null},
                { concepto: 'Propósito del puesto', index: 'purpose', valor: null},
                { concepto: 'Reporta a', index: 'report_to.name', valor: null},
                { concepto: 'Supervisa a', index: 'supervise_to.name', valor: null},
                { concepto: 'Grado de estudios', index: 'studies', valor: null},
                { concepto: 'Experiencia previa (años)', index: 'experience', valor: null},
                { concepto: 'Formación (conocimientos específicos)', index: 'background', valor: null},
                { concepto: 'Habilidades', index: 'abilities', valor: null},
                { concepto: 'Responsabilidades', index: 'responsibilities', valor: null},
                { concepto: 'Tiene autoridad de', index: 'authority', valor: null},
            ],

            actionsVacation: [
                {
					name: 'Detail',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openHolidayDetailModal',
                    permissions: 'vacations.show'
				}
				
			],
            actionsVacationDetails: [
				
                {
					name: 'PDF',
					icon: {
						color: 'secondary',
						icon: 'mdi-printer-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'pdfVacations',
                    permissions: 'vacations.show'
				},
				{
					name: 'Edit',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'vacations.update'
				},
				{
					name: 'Delete',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openDeleteVacationModal',
                    permissions: 'vacations.delete'
				},
			],



			actions: [
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/empleados/1/edicion'
				},
				{
					name: 'Borrar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'openDeleteModal'
				},
				
			],
			tableProps: {
				headers: [
                    {
						text: 'Tipo de eventualidad',
						align: 'left',
						value: 'type',
						class: 'table-b-border black--text'
					},
					{
						text: 'Fecha/Periodo',
						align: 'left',
						value: 'date_p',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Comentarios',
						align: 'left',
						value: 'comments',
						class: 'table-b-border black--text comment'
					},
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},

			tableAssignProps:{
				headers: [
                    {
						text: 'Folio',
						align: 'left',
						value: 'id',
						class: 'table-b-border black--text'
					},
					{
						text: 'Descripción',
						align: 'left',
						value: 'description',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Marca',
						align: 'left',
						value: 'brand',
						class: 'table-b-border black--text'
					},
					{
						text: 'No. Serie',
						align: 'left',
						value: 'serial_number',
						class: 'table-b-border black--text'
					},
					{
						text: 'Fecha asignación',
						align: 'left',
						value: 'assignment_date',
						class: 'table-b-border black--text'
					},
				],
				items: [],
				search: '',
				loading: false
			},
            tableVacations:{
                headers: [
                    {
						text: 'Periodo',
						align: 'left',
						value: 'period',
						class: 'table-b-border black--text'
					},
					{
						text: 'Año de Asignación',
						align: 'center',
						value: 'year_number',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Correspondientes',
						align: 'center',
						value: 'vacation_days_available',
						class: 'table-b-border black--text'
					},
					{
						text: 'Por Tomar',
						align: 'center',
						value: 'vacation_days_pending',
						class: 'table-b-border black--text'
					},
					{
						text: 'Acumulados',
						align: 'center',
						value: 'accumulated_days',
						class: 'table-b-border black--text'
					},
                    {
						text: 'expirados',
						align: 'center',
						value: 'expired_days',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Acciones',
						align: 'right',
                        width: '2%',
						sortable: false,
						value: 'actions',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
            },
            tableVacationsDetail:{
                headers: [
                    {
						text: 'Fecha',
						align: 'left',
						value: 'period',
						class: 'table-b-border black--text'
					},
					{
						text: 'Días tomados',
						align: 'center',
						value: 'days_taken',
						class: 'table-b-border black--text'
					},
                    {
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
                    
				],
				items: [],
				search: '',
				loading: false,
                elevation: '0',
                hideFooter: true,
            },
			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			modalDocumentsProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
            modalDeleteVacationItemProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
            modalHolidayProps:{
                visible: false,
				width: '700',
				title: 'Detalles del periodo',
				text: '',
				loading: false,
				bottomLineColor: 'primary',
				
            },
            cancelHolidayModal: {
                text: "Cerrar",
                icon: "",
				to: "",
				block: true,
				color: '',
                click: ()=>{this.modalHolidayProps.visible = false; }
            },
			
			cancelDeleteVacationItemModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: true,
				color: '',
                textColor:'',
                click: ()=>{this.modalDeleteVacationItemProps.visible = false}
            },
            acceptDeleteVacationItemModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.deleteVacationItem()}
            },
			cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: true,
				color: 'primary',
                textColor:'white',
                click: ()=>{this.modalProps.visible = false}
            },
            cancelDocumentsModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: true,
				color: '',
                textColor:'',
                click: ()=>{this.modalDocumentsProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.deleteEvent()}
            },
			acceptDocumentsModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: true,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.update(true)}
            },
            returnBtnData: {
                text: "<",
                icon: "mdi-chevron-left",
				to: "Employees",
				block: false,
                click: ()=>{}
            },

            exportBtnData: {
                text: "Descargar formato impreso",
                icon: "mdi-content-save-outline",
                block:true,
                to: "",
                disabled:false,
                click: () => {this.export()}
            },

		}
	},

	mounted(){
		this.index()
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},
		index(loadingDisable = false) {
			this.getEmployee(loadingDisable);
		},

        openDeleteDocument(key, miscDoc = false)
        {
            if(miscDoc)
                this.acceptDocumentsModal.click = () => {this.deleteMiscfile(key)}
            else
                this.acceptDocumentsModal.click = () => {this.update(true)}
            this.documentSelected = key;
            this.modalDocumentsProps.visible = true;
        },

        /**
         * Set error messages for documents
        */
        setErrorDocuments(x,message, request){
            x = x.split('.');
            let found = request.documentation[x[1]];
            this.serverErrors[`documentation.${found.type}`] = message;
        },
        /**
         * Set error messages for documents
        */
        setErrorMiscDocuments(x,message){
            x = x.split('.');
            
            this.serverErrors[`new_employee_files`] = message;
             console.log("si new employe foiles", this.serverErrors)
        },
		/**
		 * Get employee info
		 */
		getEmployee(loadingDisable = false)
		{
            if(!loadingDisable)
                this.loading = true;
			this.$api.employees.get(this.id)
				.then((resp) =>{
					console.log(resp);
                    this.employeeNumber = resp.data.employe_number;
                    this.employeeName = resp.data.name;
					this.employeeText = `${resp.data.employe_number} - ${resp.data.name} - ${Utils.getObjectValue(resp.data,'current_contract.branch_office.company.name')}`
                    this.setFormEmployee(resp);
                    this.setDataEmployee(resp);


                   
				})
				.catch((error) =>{
					console.log(error, "error get employee");
				})
				.finally(() => {
					this.loading = false;
					
				})
			;
		},

        setFormEmployee(response)
        {
            this.form = {
                ...this.form,
                employe_number: response.data.employe_number,
                name: response.data.name,
                email: response.data.email,
                business_email: response.data.business_email??"",
                profession: response.data.profession??"",
                birthplace: response.data.birthplace??"",
                birthdate: response.data.birthdate??"",
                gender: response.data.gender??"",
                marital_status: response.data.marital_status??"",
                curp: response.data.curp,
                rfc: response.data.rfc,
                infonavit_number: response.data.infonavit_number??"",
                fonacot_number: response.data.fonacot_number??"",
                nss: response.data.nss,
                phone: response.data.phone,
                blood_type: response.data.blood_type??"",
                diseases: response.data.diseases??[],
                other_diseases: response.data.other_diseases??"",
                country: response.data.country,
                street: response.data.street,
                ext_number: response.data.ext_number??"",
                int_number: response.data.int_number??"",
                zip_code: response.data.zip_code,
                neighborhood: response.data.neighborhood,
                city: response.data.city,
                state: response.data.state,
                emergency_contact: response.data.emergency_contact,
                emergency_number: response.data.emergency_number,
                payee_name: response.data.payee_name??"",
                payee_family_phone: response.data.payee_family_phone??"",
                payee_family_relationship: response.data.payee_family_relationship??"",
                photo_file: [],
                photo_url: response.data.photo_url, 
                has_childs: false,
                children: [],
                deleteEmployeeFiles: [],
                newEmployeeFiles: [],
            };

            if("children" in response.data){
                if(response.data.children.length > 0){
                    this.form.has_childs = true;

                    response.data.children.forEach(item => {
                        this.form.children.push(
                            {birthdate: item.birthdate, gender: item.gender, id: item.id}
                        )
                    });
                }
            }

           
            //part 1
            // this.form = {
            //     ...this.form, 
            //     photo_file:[], 
            //     photo_url: response.data.photo_url, 
            //     has_childs: false,
            //     children: []
            // };
            //part 2
            this.setDocuments(response.data.documents);
            this.form.miscFiles = [];
            response.data.files.forEach(file => {
                this.form.miscFiles.push({...file, loading:false})
            });
            //part 3
            this.form = {...this.form, 
                ...response.data.current_contract,
                monday_schedule_start: this.getValidTime(response.data.current_contract.monday_schedule_start),
                monday_schedule_end: this.getValidTime(response.data.current_contract.monday_schedule_end),
                tuesday_schedule_start: this.getValidTime(response.data.current_contract.tuesday_schedule_start),
                tuesday_schedule_end: this.getValidTime(response.data.current_contract.tuesday_schedule_end),
                wednesday_schedule_start: this.getValidTime(response.data.current_contract.wednesday_schedule_start),
                wednesday_schedule_end: this.getValidTime(response.data.current_contract.wednesday_schedule_end),
                thursday_schedule_start: this.getValidTime(response.data.current_contract.thursday_schedule_start),
                thursday_schedule_end: this.getValidTime(response.data.current_contract.thursday_schedule_end),
                friday_schedule_start: this.getValidTime(response.data.current_contract.friday_schedule_start),
                friday_schedule_end: this.getValidTime(response.data.current_contract.friday_schedule_end),
                saturday_schedule_start: this.getValidTime(response.data.current_contract.saturday_schedule_start),
                saturday_schedule_end: this.getValidTime(response.data.current_contract.saturday_schedule_end),
                sunday_schedule_start: this.getValidTime(response.data.current_contract.sunday_schedule_start),
                sunday_schedule_end: this.getValidTime(response.data.current_contract.sunday_schedule_end),
                position_id: response.data.current_contract.position?.id, 
                branch_office_id: response.data.current_contract?.branch_office?.id,
                company: (response.data.current_contract.branch_office?.company?.id),
                contract_file: [],
                contract_path: response.data.current_contract?.contract_url??"",
                // contract_multifiles: response.data.current_contract?.files??[],
            };

            this.form.contract_multifiles = response.data?.current_contract?.files.map((item) => {
                let nameFile = item.file.split("/");
                nameFile = nameFile[nameFile.length-1];
                return {url: item.file_url, path: item.file, name: nameFile.replace(/(.{40})..+/, "$1...")}
            });

            console.log(this.form, response.data)

        },

        getValidTime(time)
        {
            if(time == '' || time == null)
                return null;
            return time.substring(0,time.length - 3)
        },

        setDocuments(documents){
            //urls
            this.form.birthCertificate_url = this.getUrlDocument(documents, 'ACTA DE NACIMIENTO'); //documents.filter((doc) => doc.type == 'ACTA DE NACIMIENTO')[0].path;
            this.form.ine_url = this.getUrlDocument(documents, 'IDENTIFICACIÓN OFICIAL');//documents.find(doc => doc.type == 'IDENTIFICACIÓN OFICIAL').path;
            this.form.curp_url = this.getUrlDocument(documents, 'CURP');//documents.filter(doc => doc.type == 'CURP')[0].path;
            this.form.rfc_url = this.getUrlDocument(documents, 'RFC'); //documents.filter(doc => doc.type == 'RFC')[0].path;
            this.form.nss_url = this.getUrlDocument(documents, 'NSS'); //documents.filter(doc => doc.type == 'NSS')[0].path;
            this.form.proofResidency_url = this.getUrlDocument(documents, 'COMPROBANTE DE DOMICILIO'); //documents.filter(doc => doc.type == 'COMPROBANTE DE DOMICILIO')[0].path;
            this.form.studyCert_url = this.getUrlDocument(documents, 'CERTIFICADO DE ESTUDIOS'); //documents.filter(doc => doc.type == 'CERTIFICADO DE ESTUDIOS')[0].path;
            this.form.economyStudy_url = this.getUrlDocument(documents, 'ESTUDIO SOCIOECONÓMICO'); //documents.filter(doc => doc.type == 'ESTUDIO SOCIOECONÓMICO')[0].path;
            this.form.recomendationLetter_url = this.getUrlDocument(documents, 'CARTA DE RECOMENDACIÓN'); //documents.filter(doc => doc.type == 'CARTA DE RECOMENDACIÓN')[0].path;
            this.form.psychometricTest_url = this.getUrlDocument(documents, 'PRUEBA PSICOMÉTRICA'); //documents.filter(doc => doc.type == 'PRUEBA PSICOMÉTRICA')[0].path;
            this.form.internalRules_url = this.getUrlDocument(documents,'REGLAMENTO INTERNO'); //documents.filter(doc => doc.type == 'REGLAMENTO INTERNO')[0].path;
            this.form.confidential_url = this.getUrlDocument(documents, 'CONVENCIO CONFIDENCIAL'); //documents.filter(doc => doc.type == 'CONVENCIO CONFIDENCIAL')[0].path;
            this.form.payment_url = this.getUrlDocument(documents, 'PROPUESTA ECONÓMICA'); //documents.filter(doc => doc.type == 'PROPUESTA ECONÓMICA')[0].path;
            this.form.medicalTest_url = this.getUrlDocument(documents, 'EXAMEN MÉDICO');//documents.filter(doc => doc.type == 'EXAMEN MÉDICO')[0].path;
            this.form.letter_url = this.getUrlDocument(documents, 'ANTECEDENTES PENALES'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;

            //checks
            this.form.birthCertificate_required = this.getRequiredCheckDocument(documents, 'ACTA DE NACIMIENTO'); //documents.filter((doc) => doc.type == 'ACTA DE NACIMIENTO')[0].path;
            this.form.ine_required = this.getRequiredCheckDocument(documents, 'IDENTIFICACIÓN OFICIAL');//documents.find(doc => doc.type == 'IDENTIFICACIÓN OFICIAL').path;
            this.form.curp_required = this.getRequiredCheckDocument(documents, 'CURP');//documents.filter(doc => doc.type == 'CURP')[0].path;
            this.form.rfc_required = this.getRequiredCheckDocument(documents, 'RFC'); //documents.filter(doc => doc.type == 'RFC')[0].path;
            this.form.nss_required = this.getRequiredCheckDocument(documents, 'NSS'); //documents.filter(doc => doc.type == 'NSS')[0].path;
            this.form.proofResidency_required = this.getRequiredCheckDocument(documents, 'COMPROBANTE DE DOMICILIO'); //documents.filter(doc => doc.type == 'COMPROBANTE DE DOMICILIO')[0].path;
            this.form.studyCert_required = this.getRequiredCheckDocument(documents, 'CERTIFICADO DE ESTUDIOS'); //documents.filter(doc => doc.type == 'CERTIFICADO DE ESTUDIOS')[0].path;
            this.form.economyStudy_required = this.getRequiredCheckDocument(documents, 'ESTUDIO SOCIOECONÓMICO'); //documents.filter(doc => doc.type == 'ESTUDIO SOCIOECONÓMICO')[0].path;
            this.form.recomendationLetter_required = this.getRequiredCheckDocument(documents, 'CARTA DE RECOMENDACIÓN'); //documents.filter(doc => doc.type == 'CARTA DE RECOMENDACIÓN')[0].path;
            this.form.psychometricTest_required = this.getRequiredCheckDocument(documents, 'PRUEBA PSICOMÉTRICA'); //documents.filter(doc => doc.type == 'PRUEBA PSICOMÉTRICA')[0].path;
            this.form.internalRules_required = this.getRequiredCheckDocument(documents,'REGLAMENTO INTERNO'); //documents.filter(doc => doc.type == 'REGLAMENTO INTERNO')[0].path;
            this.form.confidential_required = this.getRequiredCheckDocument(documents, 'CONVENCIO CONFIDENCIAL'); //documents.filter(doc => doc.type == 'CONVENCIO CONFIDENCIAL')[0].path;
            this.form.payment_required = this.getRequiredCheckDocument(documents, 'PROPUESTA ECONÓMICA'); //documents.filter(doc => doc.type == 'PROPUESTA ECONÓMICA')[0].path;
            this.form.medicalTest_required = this.getRequiredCheckDocument(documents, 'EXAMEN MÉDICO');//documents.filter(doc => doc.type == 'EXAMEN MÉDICO')[0].path;
            this.form.letter_required = this.getRequiredCheckDocument(documents, 'ANTECEDENTES PENALES'); //documents.filter(doc => doc.type == 'ANTECEDENTES PENALES')[0].path;
            
            //doc counts
            let totalReqDoc = 0;
            let countReqDoc = 0;

            for (let i = 0; i < documents.length; i++) {
                const doc = documents[i];
                if(doc.required)
                    totalReqDoc = totalReqDoc + 1;
                if(doc.required && doc.path != null && doc.file_url != null)
                    countReqDoc = countReqDoc + 1;
            }
            this.percReqDoc = ((countReqDoc*100)/totalReqDoc).toFixed(0);

            if (isNaN(this.percReqDoc)) {
                this.percReqDoc = 0
            }

            this.docTotalsString = `${countReqDoc}/${totalReqDoc} entregados ${this.percReqDoc}%`

        },

        /**
         * Get document's url by its type
         */
        getUrlDocument(documents, docType){
            try {
                return documents.find(doc => doc.type == docType).file_url;
            } catch (error) {
                return '';
            }
        },
        /**
         * Get document's required check by its type
         */
        getRequiredCheckDocument(documents, docType){
            try {
                return documents.find(doc => doc.type == docType).required == true ? 1 : 0;
            } catch (error) {
                return 0;
            }
        },

        getMiscFileName(stringName)
        {
            const arrName =  stringName.split("/");
            return arrName[arrName.length - 1];
        },

        updateServer(data)
        {
            
            
            let formData = this.prepareRequest(data);

            const request = Object2Form.objectToFormData(formData,'',[]);
            console.log(request)
            this.$api.employees.update('', this.id, request)
                .then((response) => {
                    this.modalDocumentsProps.visible = false;
                    console.log(response)
                    this.$store.dispatch('snackbarSuccess', `Se ha actualizado correctamente`);
                    
                    this.$refs.form.reset();
                    this.index(true);
                    // await new Promise(r => setTimeout(r, 2000));
                })
                .catch((error) => {
                    //this.setTabErrorByStep(this.step);
                    console.log(error)
                    var errors = []
                    switch(error.response.status){
                        case 400: 
                            if(("message" in error.response.data)){
                                // this.setTabErrorByServerMessages(error.response.data.message);
                                Object.keys(error.response.data.message).forEach((x) => {
                                    error.response.data.message[x].forEach((y) => {
                                        if(x.startsWith('new_employee_files')){
                                           
                                            this.setErrorMiscDocuments(x,y)
                                        }else if(x.startsWith('documentation')){
                                            console.log("documentation start")
                                            this.setErrorDocuments(x,y,formData);
                                        }else{
                                            console.log(x,'fuera')
                                            this.serverErrors[x] = Utils.deleteWord(x,y);
                                            
                                        }
                                        errors.push(Utils.deleteWord(x,y))
                                    });
                                });
                                this.$store.dispatch('snackbarError', errors.join('.<br>'));
                            }
                        break;
                        
                    }

                    console.log(this.serverErrors);
                })
                .finally(() => {
                //    this.filesLoading[indexfile] = false;
                    this.loadingMiscUpdate = false;
                    this.loadingUpdate = false;
                    this.loadingMiscDelete = false;
                    this.acceptDocumentsModal.loading = false;
                })
            ;
            // eslint-disable-next-line no-console
        },
        updateMiscFiles()
        {
            if(this.loading || this.loadingUpdate || this.loadingMiscUpdate || this.loadingMiscDelete)
                return;
            if(this.form.newEmployeeFiles.length == 0)
                return;
            this.loadingMiscUpdate = true;
            this.updateServer(this.form)
            
        },

        update(isDelete)
        {
            if(this.loading || this.loadingUpdate || this.loadingMiscUpdate || this.loadingMiscDelete)
                return;
            this.loadingUpdate = true;

            if(isDelete){
                this.acceptDocumentsModal.loading = true;
                this.form[`${this.documentSelected}`] = "";
            }

            setTimeout(() => {
                this.$refs.returnbtn.$el.focus()
            })
            this.form.newEmployeeFiles = [];
            this.updateServer(this.form)
        },

        deleteMiscfile(file)
        {
            if(this.loading || this.loadingUpdate || this.loadingMiscUpdate || this.loadingMiscDelete)
                return;
            this.loadingMiscDelete = true;
            this.acceptDocumentsModal.loading = true;
            console.log("deleteMiscfile called")
            this.form.newEmployeeFiles = [];
            file.loading = true;
            this.form.deleteEmployeeFiles.push(file.file);
            this.updateServer(this.form)
        },

        /**
         * Process data to send
        */
        prepareRequest(data)
        {
            let newData = JSON.parse(JSON.stringify(data));

            delete newData.photo;
            delete newData.documents;
           
            console.log(data, "data")
            newData.diseases = data.diseases;
            //!Part 1
            //photo_file
            delete newData.photo_file;
            if(data.photo_file instanceof File)
                newData.photo_file = data.photo_file;
            else if(data.photo_url != '')
                newData.photo_path = 'path';

            
            if(newData.business_email == "")
                delete newData.business_email;
            
            if(newData.int_number == "")
                delete newData.int_number;

            if(newData.blood_type == "" || newData.blood_type == null)
                delete newData.blood_type;

            if(newData.diseases != null){
                if(newData.diseases.find((item) => item == "OTROS") == undefined)
                    newData.other_diseases = "";
            }

            if(newData.diseases == null || newData.diseases.length == 0)
                delete newData.diseases;

            //!part 2
            //files
            newData.documentation = [];

            //birthCertificate_file
            delete newData.birthCertificate_file;
            if(data.birthCertificate_file instanceof File)
                newData.documentation.push({file:data.birthCertificate_file, type: 'ACTA DE NACIMIENTO', required: data.birthCertificate_required});
            else if(data.birthCertificate_url != '')
                newData.documentation.push({file_url: "path", type: 'ACTA DE NACIMIENTO', required: data.birthCertificate_required});
            else
                newData.documentation.push({type: 'ACTA DE NACIMIENTO', required: data.birthCertificate_required});

            //ine_file
            delete newData.ine_file;
            if(data.ine_file instanceof File)
                newData.documentation.push({file: data.ine_file, type: 'IDENTIFICACIÓN OFICIAL', required: data.ine_required});
            else if(data.ine_url != '')
                newData.documentation.push({file_url: "path", type: 'IDENTIFICACIÓN OFICIAL', required: data.ine_required});
            else
                newData.documentation.push({type: 'IDENTIFICACIÓN OFICIAL', required: data.ine_required});

            //curp_file
            delete newData.curp_file;
            if(data.curp_file instanceof File)
                newData.documentation.push({file: data.curp_file, type: 'CURP', required: data.curp_required});
            else if(data.curp_url != '')
                newData.documentation.push({file_url: "path", type: 'CURP', required: data.curp_required});
            else
                 newData.documentation.push({type: 'CURP', required: data.curp_required});

            //rfc
            delete newData.rfc_file;
            if(data.rfc_file instanceof File)
                newData.documentation.push({file:data.rfc_file, type: 'RFC', required: data.rfc_required});
            else if(data.rfc_url != '')
                newData.documentation.push({file_url: "path", type: 'RFC', required: data.rfc_required});
            else
                newData.documentation.push({type: 'RFC', required: data.rfc_required});

            //nss_file
            delete newData.nss_file;
            if(data.nss_file instanceof File)
                newData.documentation.push({file: data.nss_file, type:'NSS', required: data.nss_required});
            else if(data.nss_url != '')
                newData.documentation.push({file_url: "path", type:'NSS', required: data.nss_required});
            else
                newData.documentation.push({type:'NSS', required: data.nss_required});


            //proofResidency_file
            delete newData.proofResidency_file;
            if(data.proofResidency_file instanceof File)
                newData.documentation.push({file: data.proofResidency_file, type:'COMPROBANTE DE DOMICILIO', required: data.proofResidency_required});
            else if(data.proofResidency_url != '')
                newData.documentation.push({file_url: "path", type:'COMPROBANTE DE DOMICILIO', required: data.proofResidency_required});
            else
                newData.documentation.push({type:'COMPROBANTE DE DOMICILIO', required: data.proofResidency_required});

            //studyCert_file
            delete newData.studyCert_file;
            if(data.studyCert_file instanceof File)
                newData.documentation.push({file: data.studyCert_file, type:'CERTIFICADO DE ESTUDIOS', required: data.studyCert_required});
            else if(data.studyCert_url != '')
                newData.documentation.push({file_url: "path", type:'CERTIFICADO DE ESTUDIOS', required: data.studyCert_required});
            else
                newData.documentation.push({type:'CERTIFICADO DE ESTUDIOS', required: data.studyCert_required});

            //economyStudy_file
            delete newData.economyStudy_file;
            if(data.economyStudy_file instanceof File)
                newData.documentation.push({file: data.economyStudy_file, type:'ESTUDIO SOCIOECONÓMICO', required: data.economyStudy_required});
            else if(data.economyStudy_url != '')
                newData.documentation.push({file_url: "path", type:'ESTUDIO SOCIOECONÓMICO', required: data.economyStudy_required});
            else
                newData.documentation.push({type:'ESTUDIO SOCIOECONÓMICO', required: data.economyStudy_required});

            //recomendationLetter_file
            delete newData.recomendationLetter_file;
            if(data.recomendationLetter_file instanceof File)
                newData.documentation.push({file: data.recomendationLetter_file, type:'CARTA DE RECOMENDACIÓN', required: data.recomendationLetter_required});
            else if(data.recomendationLetter_url != '')
                newData.documentation.push({file_url: "path", type:'CARTA DE RECOMENDACIÓN', required: data.recomendationLetter_required});
            else
                newData.documentation.push({type:'CARTA DE RECOMENDACIÓN', required: data.recomendationLetter_required});

            //psychometricTest_file
            delete newData.psychometricTest_file;
            if(data.psychometricTest_file instanceof File)
                newData.documentation.push({file: data.psychometricTest_file, type:'PRUEBA PSICOMÉTRICA', required: data.psychometricTest_required});
            else if(data.psychometricTest_url != '')
                newData.documentation.push({file_url: "path", type:'PRUEBA PSICOMÉTRICA', required: data.psychometricTest_required});
            else
                newData.documentation.push({type:'PRUEBA PSICOMÉTRICA', required: data.psychometricTest_required});

            //internalRules_file
            delete newData.internalRules_file;
            if(data.internalRules_file instanceof File)
                newData.documentation.push({file: data.internalRules_file, type:'REGLAMENTO INTERNO', required: data.internalRules_required});
            else if(data.internalRules_url != '')
                newData.documentation.push({file_url: "path", type:'REGLAMENTO INTERNO', required: data.internalRules_required});
            else
                newData.documentation.push({type:'REGLAMENTO INTERNO', required: data.internalRules_required});

            //confidential_file
            delete newData.confidential_file;
            if(data.confidential_file instanceof File)
                newData.documentation.push({file: data.confidential_file, type:'CONVENCIO CONFIDENCIAL', required: data.confidential_required});
            else if(data.confidential_url != '')
                newData.documentation.push({file_url: "path", type:'CONVENCIO CONFIDENCIAL', required: data.confidential_required});
            else
                newData.documentation.push({type:'CONVENCIO CONFIDENCIAL', required: data.confidential_required});

            //payment_file
            delete newData.payment_file;
            if(data.payment_file instanceof File)
                newData.documentation.push({file: data.payment_file, type:'PROPUESTA ECONÓMICA', required: data.payment_required});
            else if(data.payment_url != '')
                newData.documentation.push({file_url: "path", type:'PROPUESTA ECONÓMICA', required: data.payment_required});
            else
                newData.documentation.push({type:'PROPUESTA ECONÓMICA', required: data.payment_required});

            //medicalTest_file
            delete newData.medicalTest_file;
            if(data.medicalTest_file instanceof File)
                newData.documentation.push({file: data.medicalTest_file, type:'EXAMEN MÉDICO', required: data.medicalTest_required});
            else if(data.medicalTest_url != '')
                newData.documentation.push({file_url: "path", type:'EXAMEN MÉDICO', required: data.medicalTest_required});
            else
                newData.documentation.push({type:'EXAMEN MÉDICO', required: data.medicalTest_required});

            //letter_file
            delete newData.letter_file;
            if(data.letter_file instanceof File)
                newData.documentation.push({file: data.letter_file, type:'ANTECEDENTES PENALES', required: data.letter_required});
            else if(data.letter_url != '')
                newData.documentation.push({file_url: "path", type:'ANTECEDENTES PENALES', required: data.letter_required});
            else
                newData.documentation.push({type:'ANTECEDENTES PENALES', required: data.letter_required});
            
            //misc documents
            if(data.newEmployeeFiles.length > 0){
                newData.new_employee_files = [];
                for (let i = 0; i < data.newEmployeeFiles.length; i++) {
                    const element = data.newEmployeeFiles[i];
                    newData.new_employee_files.push({file: element});
                }
            }
            if(data.deleteEmployeeFiles.length > 0){
                newData.delete_employee_files = [];
                for (let i = 0; i < data.deleteEmployeeFiles.length; i++) {
                    const element = data.deleteEmployeeFiles[i];
                    newData.delete_employee_files.push({file_path: element});
                }
            }

            //!part 3
            newData.contract = new Object();
            newData.contract["admission_date"] = data.admission_date;
            newData.contract["start_date"] = data.start_date;
            newData.contract["end_date"] = data.end_date;
            newData.contract["type"] = data.type;
            newData.contract["mode"] = data.mode;
            newData.contract["payroll_type"] = data.payroll_type;
            newData.contract["fiscal_salary"] = data.fiscal_salary;
            newData.contract["integrated_salary"] = data.integrated_salary;
            newData.contract["support_transportation"] = data.support_transportation?true:false;
            newData.contract["meal_schedule"] = data.meal_schedule?true:false;
            newData.contract["monday_schedule_start"] = data.monday_schedule_start;
            newData.contract["monday_schedule_end"] = data.monday_schedule_end;
            newData.contract["tuesday_schedule_start"] = data.tuesday_schedule_start;
            newData.contract["tuesday_schedule_end"] = data.tuesday_schedule_end;
            newData.contract["wednesday_schedule_start"] = data.wednesday_schedule_start;
            newData.contract["wednesday_schedule_end"] = data.wednesday_schedule_end;
            newData.contract["thursday_schedule_start"] = data.thursday_schedule_start;
            newData.contract["thursday_schedule_end"] = data.thursday_schedule_end;
            newData.contract["friday_schedule_start"] = data.friday_schedule_start;
            newData.contract["friday_schedule_end"] = data.friday_schedule_end;
            
            if( data.saturday_schedule_start != '' &&  data.saturday_schedule_start != null)
                newData.contract["saturday_schedule_start"] = data.saturday_schedule_start;
            if( data.saturday_schedule_end != '' &&  data.saturday_schedule_end != null)
                newData.contract["saturday_schedule_end"] = data.saturday_schedule_end;
            
            if(data.sunday_schedule_start != '' && data.sunday_schedule_start != null)
                newData.contract["sunday_schedule_start"] = data.sunday_schedule_start;
            if(data.sunday_schedule_end != ''&& data.sunday_schedule_end != null)
                newData.contract["sunday_schedule_end"] = data.sunday_schedule_end;

            newData.contract["branch_office_id"] = data.branch_office_id;
            newData.contract["position_id"] = data.position_id;
            newData.contract["repeat_schedule"] = data.repeat_schedule?1:0;
             
            if(data.type == 'INDETERMINADO'){
                delete newData.contract['start_date'];
                delete newData.contract['end_date'];
            }

            //contract_file
            delete newData.contract_file;
            // if(data.contract_file instanceof File){
            //     newData.contract["contract_file"] = data.contract_file;
            // }else if(data.contract_path != '')
            //     newData.contract["contract_path"] = data.contract_path;
            // else if(data.contract_path == "")
            //     delete newData.contract["contract_path"];

            //new files
            if(data.contract_file.length > 0){
                newData.contract["new_contract_files"] = data.contract_file.map((item) =>{
                    console.log(item)
                    return {file: item};
                });
            }

            //deleted files
            if(data.delete_contract_files.length > 0){
                newData.contract["delete_contract_files"] = data.delete_contract_files
            }
            delete newData.files;
            delete newData.delete_contract_files;
            delete newData.contract_multifiles;


            delete newData.position;
            delete newData.branch_office;
            delete newData.miscFiles;

            console.log(newData, 99);
            

            return newData;
        },


        setDataEmployee(resp)
        {
            // { concepto: 'Folio', index: 'id', valor: null },

            //position 
            this.positionId = resp.data.current_contract?.position?.id;
            //data
            //diseases text
            let distext = '';
            resp.data.diseases?.forEach(br => {
                distext = distext+`${br}\n`;
            });
            //other disease
            distext = distext+`${resp.data.other_diseases??""}\n`;
            resp.data.diseases = distext;
            this.generalPersonal.push(
                
                {concepto: "Número de empleado", index: "", valor: resp.data.employe_number,},
                {concepto: "Nombre completo", index: "", valor: resp.data.name,},
                {concepto: "Correo electrónico laboral", index: "", valor: resp.data.business_email??"",},
                {concepto: "Correo electrónico personal", index: "", valor: resp.data.email,},
                {concepto: "Profesión", index: "", valor:resp.data.profession??"",},
                {concepto: "Lugar de nacimiento", index: "", valor: resp.data.birthplace??"",},
                {concepto: "Fecha de nacimiento", index: "", valor: Utils.globalDateFormat(resp.data.birthdate??""),},
                {concepto: "Género", index: "", valor: resp.data.gender??"",},
                {concepto: "Estado civil", index: "", valor: resp.data.marital_status??"",},
                {concepto: "CURP", index: "", valor: resp.data.curp,},
                {concepto: "RFC", index: "", valor: resp.data.rfc,},
                {concepto: "FONACOT", index: "", valor: resp.data.fonacot_number??"",},
                {concepto: "INFONAVIT", index: "", valor: resp.data.infonavit_number??"",},
                {concepto: "NSS", index: "", valor: resp.data.nss,},
                {concepto: "Teléfono", index: "", valor: resp.data.phone,},
                {concepto: "Tipo de sangre", index: "", valor:resp.data.blood_type??"",},
                {concepto: "Domicilio", index: "", valor: `${resp.data.street} ${resp.data.ext_number} ${resp.data.int_number}` },
                {concepto: "Colonia", index: "", valor: resp.data.neighborhood,},
                {concepto: "Municipio", index: "", valor: resp.data.city,},
                {concepto: "Estado", index: "", valor: resp.data.state,},
                {concepto: "Código postal", index: "", valor: resp.data.zip_code,},
                {concepto: "Enfermedades crónicas", index: "diseases", valor: resp.data.diseases}, //diseases 
                {concepto: "Contacto de emergencia", index: "", valor: resp.data.emergency_contact,},
                {concepto: "Teléfono de emergencia", index: "", valor: resp.data.emergency_number,},
                
                
            );

            //img
            this.employeeImg = resp.data.photo_url??"";

           


            //payee
            this.generalpayee.push(
                {concepto: "Nombre del beneficiario", index: "", valor: resp.data.payee_name??""},
                {concepto: "Teléfono del beneficiario", index: "", valor: resp.data.payee_family_phone??""},
                {concepto: "Parentesco del beneficiario", index: "", valor: resp.data.payee_family_relationship??""},
            );

            //children
            this.generalChildren.info.push(
                {concepto: "Cantidad de hijos/hijas", index: "", valor: resp.data.children.length},
            );
            //push children
            resp.data.children.forEach(child => {
                let info = [];
                info.push(
                    {concepto: "Fecha de nacimiento", index: "", valor: child.birthdate},
                    {concepto: "Género", index: "", valor: child.gender},
                );
                this.generalChildren.children.push(
                    info
                );
            });

            
            //part 3
            this.generalContract.push( 
                
                
                {concepto: "Empresa", index: "", valor: (resp.data.current_contract.branch_office?.company?.name)},
                {concepto: "Sucursal", index: "", valor: ""},
                {concepto: "Fecha de ingreso", index: "", valor: Utils.globalDateFormat(resp.data.current_contract.admission_date??"")},
                {concepto: "Tipo de contrato", index: "", valor: resp.data.current_contract.type},
                {concepto: "Modalidad", index: "", valor: resp.data.current_contract.mode},
                {concepto: "Puesto", index: "", valor: resp.data.current_contract.position.name},
                {concepto: "Periodicidad de pago", index: "", valor: resp.data.current_contract.payroll_type},
                {concepto: "Sueldo contratado", index: "", valor: Utils.currencyFormat(resp.data?.signed_salary??0,"")},
                {concepto: "Sueldo fiscal", index: "", valor: Utils.currencyFormat(resp.data?.current_contract.fiscal_salary??0,"")},
                {concepto: "Sueldo integradora", index: "", valor: Utils.currencyFormat(resp.data?.current_contract.integrated_salary??0, "")},
                {concepto: "Requiere apoyo para transporte", index: "", valor: resp.data.current_contract.support_transportation ? "Si":"No"},
                {concepto: "Debe registrar horario de comida", index: "", valor: resp.data.current_contract.meal_schedule ? "Si": "No"},
                {concepto: "Recibe compensación por horas extra", index: "", valor: resp.data.current_contract.has_overtime_payment ? "Si": "No"},
                
            );

            if(resp.data.current_contract.repeat_schedule){
                this.generalContract.push(
                    {concepto: "Horario", index: "", valor: `L - V ${resp.data.current_contract.monday_schedule_start} a ${resp.data.current_contract.monday_schedule_end}`},
                );
            }else{
                this.generalContract.push(
                    {concepto: "Horario lunes", index: "", valor: `${resp.data.current_contract.monday_schedule_start} a ${resp.data.current_contract.monday_schedule_end}`},
                    {concepto: "Horario martes", index: "", valor: `${resp.data.current_contract.tuesday_schedule_start} a ${resp.data.current_contract.tuesday_schedule_end}`},
                    {concepto: "Horario miercoles", index: "", valor: `${resp.data.current_contract.wednesday_schedule_start} a ${resp.data.current_contract.wednesday_schedule_end}`},
                    {concepto: "Horario jueves", index: "", valor: `${resp.data.current_contract.thursday_schedule_start} a ${resp.data.current_contract.thursday_schedule_end}`},
                    {concepto: "Horario viernes", index: "", valor: `${resp.data.current_contract.friday_schedule_start} a ${resp.data.current_contract.friday_schedule_end}`},
                );
            }
            if(resp.data.current_contract.saturday_schedule_start != null){
                this.generalContract.push(
                    {concepto: "Horario sábado", index: "", valor: `${resp.data.current_contract.saturday_schedule_start} a ${resp.data.current_contract.saturday_schedule_end}`}
                );
            }
            if(resp.data.current_contract.sunday_schedule_start != null){
                this.generalContract.push(
                    {concepto: "Horario domingo", index: "", valor: `${resp.data.current_contract.sunday_schedule_start} a ${resp.data.current_contract.sunday_schedule_end}`}
                );
            }
            // console.log(resp.data.current_contract?.contract_url)
            // if(resp.data.current_contract?.contract_url != '' && resp.data.current_contract?.contract_url != null){
            //     this.generalContract.push(
            //         {concepto: 'PDF de contratos', index: 'contract_url', valor: resp.data.current_contract?.contract_url??"", isFile:true}
            //     );
            // }else{
            //     this.generalContract.push(
            //         { concepto: 'PDF de contratos', index: 'slug', valor: '' },
            //     );
            // }
            if(resp.data.current_contract.files != null && resp.data.current_contract.files.length > 0){
                this.files = resp.data.current_contract?.files.map((item) => {
                    let nameFile = item.file.split("/");
                    nameFile = nameFile[nameFile.length-1];
                    return {url: item.file_url, name:nameFile}
                });
            }



            //set values for user view

        },


		/**
		 * Load eventualities
		 */
		getEvents()
		{
			if(this.eventsLoaded)
				return;
			this.eventsLoaded = true;
			this.tableProps.loading = true;
			this.$api.eventuality.getFromEmployee(this.id)
				.then((resp) =>{
					this.tableProps.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						// x.actions[0].action = {disabled: true, name: '', params: { id: x.id } };
						x.actions[0].action = {name: 'EmployeeEventEdit', params: {  employee: this.id, eventId:x.id } };
						//x.actions[1].action = {disabled: true, name: '', params: { id: x.id } };
						//console.log(x.actions);

						// if(x.type == 'INASISTENCIAS'){
						// 	x.actions[0].action = { name: 'EmployeeEventEdit', params: {  employee: this.id, eventId:x.id } };
						// 	x.actions[0] = {...x.actions[0], disabled:false};
						// 	// x.actions[1].action = { name: 'EmployeeStatistic', params: { id: x.id } };
						// }
					
						
						x.date_p = Utils.globalDateFormat(x.start_date);
						if(x.start_date != x.end_date)
							x.date_p = `${Utils.globalDateFormat(x.start_date)} - ${Utils.globalDateFormat(x.end_date)}`;

						x.comments = Utils.trimStringAt(x.comments, 50);
						x.type = this.changeType(x.type);
						x['parameters'] = { id: x.id, name: `${x.type} - ${x.date_p}`};
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error statics get employee");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
		},


		/**
		 * Load business assets
		 */
		getAssets()
		{
			if(this.assetsLoadded)
				return;
			this.assetsLoadded = true;
			this.tableAssignProps.loading = true;
			this.$api.inventory.getFromEmployee(this.id)
				.then((resp) =>{
					this.tableAssignProps.items = resp.data.map((x) => {
						

					
                        x.assignment_date = Utils.globalDateFormat(x.assignment_date);
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error statics get employee");
				})
				.finally(() => {
					this.tableAssignProps.loading = false;
				})
			;
		},

        getHoliday(force = true)
		{
			if(this.holidayLoaded && force)
				return;
			this.tableVacations.items = [];
            this.holidayLoaded = true;
			this.tableVacations.loading = true;

			this.$api.employees.getVacationByEmployee(this.id)
				.then((resp) =>{
                        
                    this.tableVacations.items = resp.data.map((x) => {
                        x['actions'] = JSON.parse(JSON.stringify(this.actionsVacation))

                        try {
                            let per = x.period.split("-");
                            x.period = `${per[2]}/${per[1]}/${per[0]} - ${per[5]}/${per[4]}/${per[3]}`
                        } catch (error) {
                            console.log(error);
                            
                        }
                        x['parameters'] = { id: x.year_number, name: `${x.period}`};

                        x.expired_days??0;

                        return x
					});
				})
				.catch((error) =>{
					console.log(error, "error statics get employee");
				})
				.finally(() => {
					this.tableVacations.loading = false;
				})
			;
		},

		/**
		 * Change eventuality type text
		 */
		changeType(type)
		{
			switch (type) {
				case 'REGISTRAR VACACIONES':
					return 'Vacaciones';
				
				case 'INCAPACIDADES':
					return 'Incapacidad';
				
				case 'PERMISOS SIN GOCE':
					return 'Permiso sin goce';
				
				case 'PERMISOS CON GOCE':
					return 'Permiso con goce';

				case 'INASISTENCIAS':
					return 'Falta';
				
				case 'DÍAS DE DESCANSO':
					return 'Días de descanso';

				case 'BONOS':
					return 'Bono';

				case 'SUSPENSIONES':
					return 'Suspensión';

				case 'PRÉSTAMOS':
					return 'Préstamo';

				case 'DÍAS EXTRA':
					return 'Días extra';

				case 'PATERNIDAD':
					return 'Paternidad';

				case 'MATERNIDAD':
					return 'Maternidad';
			
				default:
					return '';
			}
		},

        /**
		 * vacation details modal
        */
        openHolidayDetailModal(parameters)
        {
            this.yearVacationId = parameters.id;
            this.tableVacationsDetail.items = [];
			this.modalHolidayProps = {
				...this.modalHolidayProps,
				title: `Detalles del periodo`,
                text: `Vacaciones correspondientes al año ${parameters.id} de labores`,
				
			}
            this.modalHolidayProps.visible = true;
			this.loadVacationDetails(parameters);
        },

        loadVacationDetails()
        {
            this.tableVacationsDetail.items = [];
            this.tableVacationsDetail.loading = true;
			this.$api.employees.getVacationEmployeeDetails(this.id, this.yearVacationId)
				.then((resp) =>{
					this.tableVacationsDetail.items = resp.data.map((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actionsVacationDetails))

                    
						let year = "";
						x.actions[1].action = { name: 'EmployeeVacationsEdit', params: { id: this.id, vacation_id:  x.id_holiday, } }
                        try {
                            let per = x.period.split("-");
                            year = per[0];
                            x.period = `${per[2]}/${per[1]}/${per[0]} - ${per[5]}/${per[4]}/${per[3]}`
                        } catch (error) {
                            console.log(error);
                            
                        }
						
						x['parameters'] = { id: x.id_holiday, name: x.period, year:year, yearNumber:this.yearVacationId};
						
						
						return x
					});
				})
				.catch((error) =>{
					console.log(error, "error statics get employee");
				})
				.finally(() => {
					this.tableVacationsDetail.loading = false;
				})
			;
        },

        /**
		 * Open modal to delete vacation
        */
		openDeleteVacationModal(parameters){
            // this.modalHolidayProps.visible = false;
            console.log("open", parameters)
			this.vacationId = parameters.id;
			this.modalDeleteVacationItemProps = {
				...this.modalDeleteVacationItemProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
			this.modalDeleteVacationItemProps.visible = true;
		},

        /**
		 *  delete vacation
        */
        deleteVacationItem()
        {
            this.acceptDeleteVacationItemModal.loading = true;
			this.$api.employees.deleteVacation(this.vacationId)
			// eslint-disable-next-line
			.then((response) => {
				console.log("DELETED");
				this.$store.dispatch('snackbarSuccess', `Se eliminó correctamente`);
				
				this.getHoliday(false);
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalDeleteVacationItemProps.visible = false;
				this.acceptDeleteVacationItemModal.loading = false;
                this.modalHolidayProps.visible = false;
			})
        },


        /**
		 * Download vacations pdf
		 */
        pdfVacations(parameters)
        {
            console.log(parameters, "parrrr");
            this.modalHolidayProps.loading = true;
            // this.$store.state.loading = true;
            this.$api.employees.pdfVacations(parameters.id, parameters.yearNumber)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `${this.employeeName.replace(" ","_").toUpperCase()}_${parameters.year}_${parameters.id}.pdf`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.modalHolidayProps.loading = false;
                    // this.$store.state.loading = false;
                })
            ;
        },

		/**
		 * Open modal to delete event
		 */
		openDeleteModal(parameters){
			this.eventId = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
			this.modalProps.visible = true;
		},

        

		/**
		 * Delete event
		 */
		deleteEvent(){
			this.acceptModal.loading = true;
			this.$api.eventuality.delete(this.eventId)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se eliminó la eventualidad`);
				this.eventsLoaded = false;
				this.getEvents()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},

        export()
        {
            this.exportBtnData.loading = true;
            this.$store.state.loading = true;
            this.$api.employees.exportEmployeeReport(this.id)
                .then((resp) => {
                    const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                    "download",
                    `employee_report_${this.employeeNumber}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.exportBtnData.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },

        getPosition(position){
            if(position == null || position == "" || position == undefined)
                return;

			//this.$store.state.overlay = true
			this.loadingPosition = true;
			this.$api.positions.get(position)
			.then((response) => {
                this.positionItems.forEach((y) => {
                    y.valor = Utils.getObjectValue(response.data,y.index);
                    if(y.index == 'requires_travel' || y.index == 'requires_english' || y.index == 'have_persons_in_charge')
                        y.valor = y.valor ? 'Si' : 'No';
                });
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loadingPosition = false;
			})
		},

    }
}
</script>

<style lang="scss">
    .text-col{
        color: var(--v-normalText-base) !important;
    }
    .tab{
		&-header{
			font-size: 0.8125rem !important;
			color: var(--v-normalText-lighten4) !important;
			justify-content: flex-start !important;
			text-transform: unset !important;
		}

		&-active{
			color: var(--v-normalText-base) !important;
		}

		&-error{
			color: var(--v-warning-base) !important;
		}

    
	}

    .max-width-statics-btn{
        max-width: 390px !important;
    }

    .text-center-web{
        text-align: -webkit-center;
    }


    .documents{

        &-check{
            scale: 1.5;
            padding-top: 6px;
        }
    }
	
	
</style>